import React, { forwardRef } from "react";

// Customizable Area Start

import {
    StyleSheet
} from 'react-native';

import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import MaterialTable, { MTableToolbar, Icons } from 'material-table';
import Paper from '@material-ui/core/Paper';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

// Customizable Area End

import ProductManagementController, {
    Props
} from './ProductManagementController.web';

import './ProductManagement.web.css';

import Loader from "../../../components/src/Loader.web";

const tableIcons: Icons = {
    Export: forwardRef((props: any, ref: any) => <button className="product-button" style={{ width: '125px' }} {...props} ref={ref}>Export data</button>)
}

export default class ProductManagement extends ProductManagementController {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { productList } = this.state;
        return (
            <>
                <div className="product-management-main-header">
                    Product Management / Manage Product
                </div>
                <div className="product-management-container">
                    {this.state.loading && <Loader loading={this.state.loading} />}
                    <div className="product-management-panel-header">
                        Product Management
                    </div>
                    {productList !== null &&
                        <div className="product-management-view">
                            <div style={{ maxWidth: '100%' }}>
                                <MaterialTable
                                    title=""
                                    icons={tableIcons}
                                    components={{
                                        Container: (props: any) => <Paper {...props} elevation={0} />,
                                        Toolbar: (props: any) => {
                                            // This will let you use your own Title while keeping the search
                                            const propsCopy = { ...props };
                                            return (
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'space-between', marginLeft: '-16px', marginBottom: '10px' }}>
                                                    <MTableToolbar {...props} searchFieldVariant="standard" disableUnderline="true" />
                                                    <button className="product-button" style={{ marginLeft: '-25px', marginRight: '15px' }}>Fetch from ERP</button>
                                                    <RefreshIcon style={{ cursor: 'pointer' }} />
                                                    <span style={{ flex: '1 1 auto' }}></span>
                                                </div>
                                            )
                                        }
                                    }}
                                    columns={[
                                        {
                                            title: 'PRODUCT NAME', field: 'name', render: (rowData: any) => {
                                                return (
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', width: 175 }}>
                                                        <img src={rowData?.images[0]?.image} style={{ width: 40, borderRadius: 10, marginRight: 10 }} />
                                                        <span>{rowData?.name}</span>
                                                    </div>
                                                )
                                            }
                                        },
                                        { title: 'PRODUCT ID', field: 'id' },
                                        { title: 'BARCODE', field: 'barcode' },
                                        { title: 'DESCRIPTION', field: 'description' },
                                        { title: 'UNIT PRICE', field: 'unit_price' },
                                        { title: 'PROMOTIONAL', field: 'promotional_price' },
                                        { title: 'EXPIRY', field: 'expiry' }
                                    ]}
                                    data={productList != null ? productList : []}
                                    options={{
                                        search: true,
                                        exportButton: {
                                            csv: true,
                                            pdf: true
                                        },
                                        searchFieldAlignment: "left",
                                        toolbarButtonAlignment: 'left',
                                        searchFieldStyle: {
                                            width: '95%',
                                            height: 40,
                                            fontSize: 15,
                                            border: 'none',
                                            background: 'whitesmoke',
                                            borderRadius: 15,
                                            color: '#8f92a1',
                                            paddingLeft: 12,
                                            outline: 'none',
                                            marginRight: 15
                                        },
                                        headerStyle: {
                                            height: 44,
                                            flexDirection: 'row',
                                            textAlign: 'left',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            backgroundColor: 'whitesmoke',
                                            fontFamily: 'Roboto',
                                            fontSize: 12,
                                            fontWeight: 500,
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 1.67,
                                            letterSpacing: 1,
                                            color: '#000000'
                                        },
                                        rowStyle: rowData => ({
                                            // backgroundColor: (rowData.tableData.id % 2 == 0) ? '#ffffff' : '#f4f8f9',
                                            backgroundColor: (rowData.tableData.id === this.state.selectedRow?.tableData?.id) ? '#dedede' : '#ffffff',
                                            fontFamily: 'Roboto',
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineLeight: 1.38,
                                            letterSpacing: -0.4,
                                            color: '#171717'
                                        })
                                    }}
                                    onRowClick={((evt, selectedRow) => {
                                        this.setState({ selectedRow: selectedRow });
                                        this.props.history.push({
                                            pathname: '/product-details',
                                            state: { productId: selectedRow.id }
                                        })
                                    })}
                                />
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }
}

// Customizable Area Start
const styles = StyleSheet.create({
});
// Customizable Area End