import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

import { toast } from 'react-toastify';

export interface Props {
    navigation: any;
    id: string;
    history: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showPass: boolean;
    loading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    emailAccountLoginApiCallId: string = "";

    // Customizable Area End

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            showPass: false,
            loading: false
        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            if (responseJson?.data) {
                if (apiRequestCallId === this.emailAccountLoginApiCallId) {
                    // local state update
                    this.setState({ loading: false });
                    toast.success("Login Success !");
                    localStorage.setItem('user', JSON.stringify(responseJson?.data));
                    localStorage.setItem('meta', JSON.stringify(responseJson?.meta));
                    localStorage.setItem('token', responseJson?.meta?.token);
                    this.routeToAll("/home");
                }
            } else {
                toast.error(responseJson?.errors[0]?.sign_in);
                // local state update
                this.setState({ loading: false });
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.loadInitialData();
    }

    loadInitialData() {
    }

    routeToAll(route: string) {
        this.props?.history?.push(route);
    }

    togglePassword = () => {
        this.setState((previousState) => ({
            showPass: !previousState.showPass
        }));
    };

    onSubmit = (values: any) => {
        console.log(values);
        this.setState({ loading: true });
        const header = { "Content-Type": configJSON.loginApiContentType };
        const data = { type: "email_account", attributes: values };
        const httpBody = { data: data };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.emailAccountLoginApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.loginAPiMethod);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_admin/sign_in");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // Customizable Area End
}
