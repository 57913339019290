import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from 'react-toastify';

// Customizable Area Start
// import { } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    history: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    productList: any;
    selectedRow: any;
    loading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ProductManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    productManagementApiCallId: string = "";

    // Customizable Area End

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            productList: null,
            selectedRow: null,
            loading: false
        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            runEngine.debugLog("API Message Recived", message);
            
            if (apiRequestCallId === this.productManagementApiCallId) {
                if (responseJson?.data) {
                    var arr = [];
                    for (var i = 0; i < responseJson.data.length; i++) {
                        responseJson.data[i]['attributes']['id'] = responseJson.data[i].id;
                        arr.push(responseJson.data[i]['attributes']);
                    }
                    // local state update
                    this.setState({
                        productList: arr,
                        loading: false
                    });
                } else {
                    // local state update
                    this.setState({ loading: false });
                    if (responseJson?.errors[0]?.token) {
                        toast.error(responseJson?.errors[0]?.token);
                    } else {
                        toast.error("some issue in server, try again !");
                    }
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.loadInitialData();
    }

    loadInitialData() {
        this.setState({ loading: true });
        const header = { "token": localStorage.getItem('token') };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.productManagementApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_admin/products");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}