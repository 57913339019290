Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "WarehouseIntegration";
exports.labelBodyText = "WarehouseIntegration Body";
exports.getProductDetailsEndPoint = "bx_block_admin/products"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End