import React from "react";

// Customizable Area Start

import {
    StyleSheet
} from 'react-native';

import { imgVeroShopLogo } from "./assets";

// Customizable Area End

import ForgotPasswordController, {
    Props
} from './ForgotPasswordController.web';

import './PasswordChangeNotification.web.css';

export default class PasswordChangeNotification extends ForgotPasswordController {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <div className="password-change-notification-container">
                <div className="password-change-notification-header">
                    <img src={imgVeroShopLogo} alt="Logo" className="Sa-logo" onClick={() => this.routeToAll('/home')} />
                </div>
                <div className="password-change-notification-form-container">
                    <div className="password-change-notification-form-header-text-container">
                        <h1 className="main-header">Password Changed</h1>
                        <h1 className="sub-header">Your password has been changed<br /> successfully</h1>
                    </div>
                    <button className="password-change-notification-form-button" onClick={() => this.routeToAll('/login')}>Back to login</button>
                </div>
            </div>
        )
    }
}

// Customizable Area Start
const styles = StyleSheet.create({
});
// Customizable Area End