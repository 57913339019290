import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

import { toast } from 'react-toastify';

export interface Props {
    navigation: any;
    id: string;
    history: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showPass: boolean;
    confirmPass: boolean;
    loading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    emailAccountRegistrationApiCallId: string = "";

    // Customizable Area End

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            showPass: false,
            confirmPass: false,
            loading: false
        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            runEngine.debugLog("API Message Recived", message);
                debugger;
            if (responseJson?.data) {
                if (apiRequestCallId === this.emailAccountRegistrationApiCallId) {
                    // local state update
                    this.setState({ loading: false });
                    toast.success("Email Account Registration Success !");
                    this.routeToAll("/login");
                }
            } else {
                if (responseJson?.errors[0]) {
                    if (responseJson?.errors[0]?.account) {
                        toast.error(responseJson?.errors[0]?.account);
                    } else if (responseJson?.errors[0]?.sign_in) {
                        toast.error(responseJson?.errors[0]?.sign_in);
                    }
                } else {
                    toast.error("Email account creation failed");
                }
                
                // local state update
                this.setState({ loading: false });
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.loadInitialData();
    }

    loadInitialData() {
    }

    routeToAll(route: string) {
        this.props?.history?.push(route);
    }

    togglePassword = () => {
        this.setState((previousState) => ({
            showPass: !previousState.showPass
        }));
    };

    confirmTogglePassword = () => {
        this.setState((previousState) => ({
            confirmPass: !previousState.confirmPass
        }));
    };

    onSubmit = (values: any) => {
        
        console.log(values);

        if (values.password !== values.confirmPassword) {
            toast.error("Passwords are not same");
            return;
        }

        this.setState({ loading: true });
        const formData = new FormData();
        formData.append('role_id', '1');
        formData.append('user_name', values.user_name);
        formData.append('address', values.address);
        formData.append('first_name', values.first_name);
        formData.append('state', values.state);
        formData.append('last_name', values.last_name);
        formData.append('country', values.country);
        formData.append('email', values.email);
        formData.append('password', values.password);
        formData.append('phone_number', values.phone_number);

        const header = {};
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.emailAccountRegistrationApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_admin/sign_up");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // Customizable Area End
}
