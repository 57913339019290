import React, { ReactNode } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Header from '../../components/src/Header.web';
import Sidebar from '../../components/src/Sidebar.web';

import './Layout.web.css';

class Layout extends React.Component<RouteComponentProps> {

    constructor(props: any) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount() {

    }

    render() {
        const { history } = this.props;
        return (
            <div className="layout-component-wrap">
                <Header history={history} />
                <div className="mainContainer">
                    <div className="veroshop-sidebar">
                        <Sidebar history={history} />
                    </div>
                    <div className="veroshop-main-panel-wrapper">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
};

export default withRouter(Layout);