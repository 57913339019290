import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// import { } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

import { toast } from 'react-toastify';

export interface Props {
    navigation: any;
    id: string;
    history: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    email: string;
    showPass: boolean;
    confirmPass: boolean;
    loading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    forgotPasswordApiCallId: string = "";
    resetPasswordApiCallId: string = "";

    // Customizable Area End

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            email: '',
            showPass: false,
            confirmPass: false,
            loading: false
        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            if (apiRequestCallId === this.forgotPasswordApiCallId) {
                if (responseJson.status === "ok") {
                    toast.success("Email Validation Success !");
                    // local state update
                    this.setState({ loading: false });
                    this.routeToAll("/reset-password-confirm");
                } else {
                    toast.error("Email Validation Failed !");
                    // local state update
                    this.setState({ loading: false });
                }
            } else if (apiRequestCallId === this.resetPasswordApiCallId) {
                if (responseJson.status === "ok") {
                    // local state update
                    toast.success("Your password changed");
                    this.setState({ loading: false });
                    this.routeToAll("/reset-password-notification");
                } else {
                    toast.error(responseJson?.error);
                    // local state update
                    this.setState({ loading: false });
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.loadInitialData();
    }

    loadInitialData() {
    }

    routeToAll(route: string) {
        this.props?.history?.push(route);
    }

    togglePassword = () => {
        this.setState((previousState) => ({
            showPass: !previousState.showPass
        }));
    };

    confirmTogglePassword = () => {
        this.setState((previousState) => ({
            confirmPass: !previousState.confirmPass
        }));
    };

    onSubmit = (values: any) => {
        console.log(values);
        this.setState({ loading: true, email: values.email });
        const header = { "Content-Type": configJSON.forgotPasswordAPiContentType };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.forgotPasswordApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_admin/password/forgot?email=" + `${values.email}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({}));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    onResetPwdSubmit = (values: any) => {

        console.log(values);

        if (values.password !== values.confirmPassword) {
            toast.error("Passwords are not same");
            return;
        }
        this.setState({ loading: true });
        const header = {
            "Content-Type": configJSON.forgotPasswordAPiContentType
        };
        const httpBody = { token: window.location.search.split("?token=")[1], password: values.password };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.resetPasswordApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_admin/password/reset");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}