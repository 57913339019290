import React, { Component } from "react";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";

import HeaderAvatar from './HeaderAvatar.web';

import { imgVeroShopLogo, imgNotification, imgSettings } from "../../blocks/dashboard/src/assets";

import './Header.web.css';

interface Props {
    history: any;
}

interface S {
    profileAnchorEl: any;
    openProfileMenu: any;
}

interface SS {
}

export default class Header extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.state = {
            profileAnchorEl: null,
            openProfileMenu: false
        }
    }

    render() {
        const { history } = this.props;
        const _this = this;
        return (
            <div className="veroshop-header">
                <div className="for-logo" onClick={() => this.routeAll("home")}>
                    <img src={imgVeroShopLogo} alt="Logo" className="Sa-logo" />
                </div>
                <span className="spacer"></span>
                <div className="vero-logo" onClick={() => this.routeAll("home")}>
                    <img src={imgNotification} alt="Logo" className="icon" />
                </div>
                <div className="vero-logo" onClick={() => this.routeAll("home")}>
                    <img src={imgSettings} alt="Logo" className="icon" />
                </div>
                <HeaderAvatar
                    history={history}
                    openProfileMenu={this.state.openProfileMenu}
                    profileAnchorEl={this.state.profileAnchorEl}
                    handleProfileClick={(event: any) => this.setState({ profileAnchorEl: event.currentTarget, openProfileMenu: true })}
                    handleProfileClose={() => this.setState({ profileAnchorEl: null, openProfileMenu: false })}
                />
            </div>
        )
    }

    routeAll(path: string) {
        this.props?.history?.push(path);
    }
}