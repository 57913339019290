export const imgVeroShopLogo = require("../assets/veroshopLogo.png");
export const imgNotification = require("../assets/notification.png");
export const imgSettings = require("../assets/settings.png");
export const imgProfile = require("../assets/profile.png");
export const dashboard = require("../assets/Dashboard.svg");
export const productManagement = require("../assets/productManagement.png");
export const accountManagement = require("../assets/accountManagement.png");
export const inventoryManagement = require("../assets/inventoryManagement.png");
export const branchAllowed = require("../assets/branch_allowed.png");
export const branchRegistered = require("../assets/branch_registered.png");
export const branchAvailable = require("../assets/branch_available.png");

