import PropTypes from "prop-types";
import React, { useState } from "react";
import { BlockComponent } from "../../framework/src/BlockComponent";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Route } from "react-router-dom";

import { Icon } from "@material-ui/core";
import { dashboard, productManagement, accountManagement, inventoryManagement } from '../../blocks/dashboard/src/assets';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

import './Sidebar.web.css';

interface Props {
    history: any;
}

interface S {
}

interface SS {
}

export default class Sidebar extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        const { history } = this.props;
        const _this = this;
        return (
            <div>
                <List component="nav" aria-label="">
                    {
                        menu.map((item: any, key: any) => <MenuItem key={key} item={item} history={history} />)
                    }
                </List>
            </div>
        )
    }
}

const menu = [
    {
        icon: <Icon><img src={dashboard} height={18} width={18} /></Icon>,
        title: "Dashboard",
        path: "/home",
        items: []
    },
    {
        icon: <Icon><img src={productManagement} height={18} width={18} /></Icon>,
        title: "Product Management",
        path: "/productManagement",
        items: []
    },
    {
        icon: <Icon><img src={accountManagement} height={18} width={18} /></Icon>,
        title: "Account Management",
        path: "/accountManagement",
        items: [
            {
                title: "Branch Management",
                path: "/branchManagement",
                icon: <FiberManualRecordRoundedIcon style={{ fontSize: 10, color: '#0072bc' }} />,
                items: []
            },
            {
                title: "Store QR Code",
                path: "/storeQRCode",
                icon: <FiberManualRecordRoundedIcon style={{ fontSize: 10, color: '#0072bc' }} />,
                items: []
            },
            {
                title: "Upgrade/Downgrade",
                path: "/upgradeDowngrade",
                icon: <FiberManualRecordRoundedIcon style={{ fontSize: 10, color: '#0072bc' }} />,
                items: []
            }
        ]
    },
    {
        icon: <Icon><img src={inventoryManagement} height={18} width={18} /></Icon>,
        title: "Inventory Management",
        path: "/inventoryManagement",
        items: [{
            title: "Product Availability",
            path: "/productAvailability",
            icon: <FiberManualRecordRoundedIcon style={{ fontSize: 10, color: '#0072bc' }} />,
            items: []
        }, {
            title: "Promotional Availability",
            path: "/promotionalAvailability",
            icon: <FiberManualRecordRoundedIcon style={{ fontSize: 10, color: '#0072bc' }} />,
            items: []
        }]
    }
];

const hasChildren = (item: any) => {

    const { items } = item;

    if (items === undefined) {
        return false;
    }

    if (items.constructor !== Array) {
        return false;
    }

    if (items.length === 0) {
        return false;
    }

    return true;
}

const MenuItem = (props: any) => {
    const { item, history } = props;
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} history={history} />;
};

const SingleLevel = (props: any) => {
    const { item, history } = props;
    const [activeItem, setActiveItem] = useState('Dashboard');
    return (
        <Route
            path={item?.path}
            exact={true}
            children={({ match }) => (
                <ListItem
                    button
                    selected={Boolean(match)}
                    className={Boolean(match) ? 'list-item-active' : ''}
                    onClick={(event: any) => {
                        history?.push(item?.path);
                        setActiveItem(event.target?.innerText);
                    }}
                >
                    <ListItemIcon className={`${Boolean(match) && 'list-item-selected'}`}>{item?.icon}</ListItemIcon>
                    <ListItemText primary={item?.title} primaryTypographyProps={{ style: {color: Boolean(match) ? '#0072bc' : '#040e19'} }} />
                </ListItem>
            )}
        />
    );
};

const MultiLevel = (props: any) => {

    const { item, history } = props;

    const { items: children } = item;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    return (
        <React.Fragment>
            <ListItem button onClick={handleClick}>
                <ListItemIcon>{item?.icon}</ListItemIcon>
                <ListItemText primary={item?.title} />
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children.map((child: any, key: any) => (
                        <MenuItem key={key} item={child} history={history} />
                    ))}
                </List>
            </Collapse>
        </React.Fragment>
    );
};