import PropTypes from "prop-types";
import React, { Component } from "react";
import { IBlock } from "../../framework/src/IBlock";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { toast } from 'react-toastify';
import Loader from './Loader.web';

interface Props {
    history: any;
    open: boolean;
    anchorEl: any;
    handleClose: any;
}

interface S {
    loading: boolean;
}

interface SS {
}

export default class ProfileMenu extends BlockComponent<Props, S, SS> {

    // Customizable Area Start

    emailAccountLogoutApiCallId: string = "";

    // Customizable Area End

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            loading: false
        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            if (apiRequestCallId === this.emailAccountLogoutApiCallId) {
                if (responseJson?.status?.code === 200) {
                    // local state update
                    this.setState({ loading: false });
                    toast.success(responseJson?.status?.message);
                    localStorage.clear();
                    this.props?.history?.push("/login");
                } else {
                    // local state update
                    this.setState({ loading: false });
                    toast.error(responseJson?.status?.message);
                }
            }
        }
        // Customizable Area End
    }

    logout = () => {
        this.setState({ loading: true });
        const header = { "User-Token": localStorage.getItem('token') };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.emailAccountLogoutApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_admin/logout");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    render() {
        const { open, anchorEl, handleClose } = this.props;
        const _this = this;
        return (
            <div>
                {this.state.loading && <Loader loading={this.state.loading} />}
                <Popover
                    style={{ marginTop: 15 }}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                >
                    <div>
                        <List component="nav" aria-label="secondary mailbox folders">
                            <ListItem button onClick={() => this.logout()}>
                                <ListItemText primary="Logout" />
                            </ListItem>
                        </List>
                    </div>
                </Popover>
            </div>
        )
    }
}