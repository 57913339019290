import React from "react";

// Customizable Area Start

import {
    StyleSheet
} from 'react-native';

import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";

import { imgVeroShopLogo } from "./assets";

// Customizable Area End

import ForgotPasswordController, {
    Props
} from './ForgotPasswordController.web';

import './ForgotPassword.web.css';

const resetPwdSchema = Yup.object().shape({
    password: Yup
        .string()
        .min(8, 'Minimum Password length is 8.')
        .max(16, 'Maximum Password length is 16')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must contain atleast a capital letter, a lowercase letter, a number and a special character.'
        )
        .required('Password is required.'),
    confirmPassword: Yup
        .string()
        .min(8, 'Minimum Password length is 8.')
        .max(16, 'Maximum Password length is 16')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must contain atleast a capital letter, a lowercase letter, a number and a special character.'
        )
        .required('Confirm Password is required.')
});

export default class ResetPassword extends ForgotPasswordController {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <div className="forgot-pwd-container">
                <div className="forgot-pwd-header">
                    <img src={imgVeroShopLogo} alt="Logo" className="Sa-logo" onClick={() => this.routeToAll('/home')} />
                </div>
                <div className="forgot-pwd-form-container">
                    <div className="forgot-pwd-form-header-text-container">
                        <h1 className="main-header">Set Password</h1>
                        <h1 className="sub-header">Set the new password</h1>
                    </div>
                    <Formik
                        initialValues={{ password: '', confirmPassword: '' }}
                        validationSchema={resetPwdSchema}
                        onSubmit={(values) => {
                            this.onResetPwdSubmit(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <Form translate="yes" onSubmit={handleSubmit}>
                                <div>
                                    <div className="input-group">
                                        <Field
                                            name="password"
                                            type={this.state.showPass ? 'text' : 'password'}
                                            id="password"
                                            placeholder='Enter New Password'
                                            className="forgot-pwd-custom-input"
                                        />
                                        {/* <button type="button" id="btnToggle" className="toggle">
                                            <i id="eyeIcon" className="fa fa-eye"></i>
                                        </button> */}
                                        <span
                                            className={this.state.showPass ? 'icon hide' : 'icon show'}
                                            onClick={() => this.togglePassword()}
                                        />
                                    </div>
                                    {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                    {touched.password && errors.password ? (
                                        <div className="error">{errors.password}</div>
                                    ) : null}
                                </div>
                                <div>
                                    <div className="input-group">
                                        <Field
                                            name="confirmPassword"
                                            type={this.state.confirmPass ? 'text' : 'password'}
                                            id="password"
                                            placeholder='Renter New Password'
                                            className="forgot-pwd-custom-input"
                                        />
                                        {/* <button type="button" id="btnToggle" className="toggle">
                                            <i id="eyeIcon" className="fa fa-eye"></i>
                                        </button> */}
                                        <span
                                            className={this.state.confirmPass ? 'icon hide' : 'icon show'}
                                            onClick={() => this.confirmTogglePassword()}
                                        />
                                    </div>
                                    {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                    {touched.confirmPassword && errors.confirmPassword ? (
                                        <div className="error">{errors.confirmPassword}</div>
                                    ) : null}
                                </div>
                                <button type="submit" className="reset-form-button">Confirm</button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        )
    }
}

// Customizable Area Start
const styles = StyleSheet.create({
});
// Customizable Area End