import React, { Component } from "react";
import { BlockComponent } from "../../framework/src/BlockComponent";

import { imgProfile } from "../../blocks/dashboard/src/assets";

import ProfileMenu from './ProfileMenu.web';

interface Props {
    history: any;
    openProfileMenu: any;
    profileAnchorEl: any;
    handleProfileClick: any;
    handleProfileClose: any;
}

interface S {
}

interface SS {
}

export default class HeaderAvatar extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        const { history, openProfileMenu, profileAnchorEl, handleProfileClose, handleProfileClick } = this.props;
        const _this = this;
        return (
            <>
                <div className="vero-logo" onClick={handleProfileClick}>
                    <img src={imgProfile} alt="Logo" className="icon" />
                </div>
                <ProfileMenu
                    history={history}
                    open={openProfileMenu}
                    anchorEl={profileAnchorEl}
                    handleClose={handleProfileClose}
                />
            </>

        )
    }
}