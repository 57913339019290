import React from "react";

// Customizable Area Start

import {
    StyleSheet
} from 'react-native';

import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";

import { imgVeroShopLogo, imgPasswordInVisible, imgPasswordVisible } from "./assets";

// Customizable Area End

import EmailAccountRegistrationController, {
    Props
} from './EmailAccountRegistrationController.web';

import './EmailAccountRegistration.web.css';

import Loader from "../../../components/src/Loader.web";

const signUpSchema = Yup.object().shape({
    user_name: Yup.string()
        .required('Retailer Name is required.'),
    address: Yup.string()
        .required('Address is required.'),
    first_name: Yup.string()
        .required('First Name is required.'),
    last_name: Yup.string()
        .required('Last Name is required.'),
    country: Yup.string()
        .required('Country is required.'),
    state: Yup.string()
        .required('State is required.'),
    email: Yup.string()
        .email('Please enter a valid email address.')
        .required('Email is required.'),
    password: Yup
        .string()
        .min(8, 'Minimum Password length is 8.')
        .max(16, 'Maximum Password length is 16')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must contain atleast a capital letter, a lowercase letter, a number and a special character.'
        )
        .required('Password is required.'),
    confirmPassword: Yup
        .string()
        .min(8, 'Minimum Password length is 8.')
        .max(16, 'Maximum Password length is 16')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must contain atleast a capital letter, a lowercase letter, a number and a special character.'
        )
        .required('Renter Password is required.'),
    phone_number: Yup
        .string()
        .max(10, 'Maximum Phone Number length is 10')
        .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            'Phone number is not valid'
        )
        .required('Phone Number is required.')
});

export default class EmailAccountRegistration extends EmailAccountRegistrationController {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <div className="signup-container">
                {this.state.loading && <Loader loading={this.state.loading} />}
                <div className="signup-header">
                    <img src={imgVeroShopLogo} alt="Logo" className="Sa-logo" onClick={() => this.routeToAll('/home')} />
                </div>
                <div className="signup-form-container">
                    <div className="signup-form-header-text-container">
                        <h1 className="main-header">Sign upto Veroshop</h1>
                        <h1 className="sub-header">Welcome back, you'be been missed</h1>
                    </div>
                    <Formik
                        initialValues={{
                            user_name: '', address: '', first_name: '', last_name: '', state: '',
                            country: '', email: '', password: '', confirmPassword: '', phone_number: ''
                        }}
                        validationSchema={signUpSchema}
                        onSubmit={(values) => {
                            debugger;
                            this.onSubmit(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <Form translate="yes" onSubmit={handleSubmit} style={{ padding: "0% 12%", marginBottom: '8%' }}>
                                <div className="form-group">
                                    <div className="form-control">
                                        <Field
                                            name="user_name"
                                            type="text"
                                            placeholder='Retailer Name'
                                            className="signup-custom-input"
                                        />
                                        {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                        {touched.user_name && errors.user_name ? (
                                            <div className="error">{errors.user_name}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-control">
                                        <Field
                                            name="address"
                                            type="text"
                                            placeholder='Address'
                                            className="signup-custom-input"
                                        />
                                        {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                        {touched.address && errors.address ? (
                                            <div className="error">{errors.address}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-control">
                                        <Field
                                            name="first_name"
                                            type="text"
                                            placeholder='First Name'
                                            className="signup-custom-input"
                                        />
                                        {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                        {touched.first_name && errors.first_name ? (
                                            <div className="error">{errors.first_name}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-control">
                                        <Field
                                            name="state"
                                            value={values.state}
                                            as="select"
                                            placeholder='State'
                                            className="signup-custom-select"
                                        >
                                            <option key="state" value="">
                                                State
                                            </option>
                                            <option key="India" value="AP">
                                                AP
                                            </option>
                                        </Field>
                                        {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                        {touched.state && errors.state ? (
                                            <div className="error">{errors.state}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-control">
                                        <Field
                                            name="last_name"
                                            type="text"
                                            placeholder='Last Name'
                                            className="signup-custom-input"
                                        />
                                        {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                        {touched.last_name && errors.last_name ? (
                                            <div className="error">{errors.last_name}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-control">
                                        <Field
                                            name="country"
                                            value={values.country}
                                            as="select"
                                            placeholder='Country'
                                            className="signup-custom-select"
                                        >
                                            <option key="country" value="">
                                                Country
                                            </option>
                                            <option key="India" value="IN">
                                                India
                                            </option>
                                        </Field>
                                        {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                        {touched.country && errors.country ? (
                                            <div className="error">{errors.country}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-control">
                                        <Field
                                            name="email"
                                            type="text"
                                            placeholder='Your Email'
                                            className="signup-custom-input"
                                            autocomplete="nope"
                                        />
                                        {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                        {touched.email && errors.email ? (
                                            <div className="error">{errors.email}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-control">
                                        <div className="input-group">
                                            <Field
                                                name="password"
                                                type={this.state.showPass ? 'text' : 'password'}
                                                id="password"
                                                placeholder='Password'
                                                className="signup-custom-input"
                                            />
                                            {/* <button type="button" id="btnToggle" className="toggle">
                                            <i id="eyeIcon" className="fa fa-eye"></i>
                                        </button> */}
                                            <span
                                                className={this.state.showPass ? 'icon hide' : 'icon show'}
                                                onClick={() => this.togglePassword()}
                                            />
                                        </div>
                                        {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                        {touched.password && errors.password ? (
                                            <div className="error">{errors.password}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-control">
                                        <Field
                                            name="phone_number"
                                            type="text"
                                            placeholder='Phone No'
                                            className="signup-custom-input"
                                        />
                                        {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                        {touched.phone_number && errors.phone_number ? (
                                            <div className="error">{errors.phone_number}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-control">
                                        <div className="input-group">
                                            <Field
                                                name="confirmPassword"
                                                type={this.state.confirmPass ? 'text' : 'password'}
                                                id="confirm password"
                                                placeholder='Renter Password'
                                                className="signup-custom-input"
                                            />
                                            {/* <button type="button" id="btnToggle" className="toggle">
                                            <i id="eyeIcon" className="fa fa-eye"></i>
                                        </button> */}
                                            <span
                                                className={this.state.confirmPass ? 'icon hide' : 'icon show'}
                                                onClick={() => this.confirmTogglePassword()}
                                            />
                                        </div>
                                        {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                        {touched.confirmPassword && errors.confirmPassword ? (
                                            <div className="error">{errors.confirmPassword}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <button type="submit" className="signup-form-button">Signup</button>
                                <p className="signup-dont-text-first">
                                    Already have an account?{'  '}
                                    <Link to="/login" className="signup-dont-text-second">Sign In</Link>
                                </p>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        )
    }
}

// Customizable Area Start
const styles = StyleSheet.create({
});
// Customizable Area End
