import React, { PureComponent } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { BlockComponent } from "../../framework/src/BlockComponent";

import Layout from "./Layout.web";

export type Props = RouteProps & {

};

interface S {
    token: string;
    loading: boolean;
}

export default class PrivateRoute extends PureComponent<Props, S>{

    constructor(props: Props) {
        super(props);
        this.state = {
            token: '',
            loading: true,
        }
    }

    async componentDidMount() {

        let tokenn = await localStorage.getItem('token');
        if (tokenn) {
            this.setState({
                token: tokenn,
                loading: false
            })
        }
        else {
            this.setState({
                loading: false
            })
        }
    }
    render() {
        const { loading, token } = this.state;
        const { path } = this.props;
        return !loading ?
            (
                (token == '') ?
                    (
                        <Redirect to='/login' />
                    )
                    :
                    (token != '') && (path == '/' || path == '/login') ?
                        (
                            <Redirect to='/home' />
                        )
                        :
                        (
                            <Layout><Route {...this.props} /></Layout>
                        )
            ) : null;
    }
};