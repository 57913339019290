import React from "react";

// Customizable Area Start

import {
    StyleSheet
} from 'react-native';

import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";

import { imgVeroShopLogo, imgPasswordInVisible, imgPasswordVisible } from "./assets";

// Customizable Area End

import EmailAccountLoginController, {
    Props
} from './EmailAccountLoginController.web';

import "./EmailAccountLoginBlock.web.css";

import Loader from "../../../components/src/Loader.web";

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Please enter a valid email address.')
        .required('Email is required.'),
    password: Yup
        .string()
        .min(8, 'Minimum Password length is 8.')
        .max(16, 'Maximum Password length is 16')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must contain atleast a capital letter, a lowercase letter, a number and a special character.'
        )
        .required('Password is required.')
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <div className="login-container">
                {this.state.loading && <Loader loading={this.state.loading} />}
                <div className="login-header">
                    <img src={imgVeroShopLogo} alt="Logo" className="Sa-logo" onClick={() => this.routeToAll('/home')} />
                </div>
                <div className="login-form-container">
                    <div className="login-form-header-text-container">
                        <h1 className="main-header">Let's Sign You In</h1>
                        <h1 className="sub-header">Welcome back, you've been missed</h1>
                    </div>
                    <Formik
                        initialValues={{ email: '', password: '', rememberMeFlag: false }}
                        validationSchema={loginSchema}
                        onSubmit={(values) => {
                            this.onSubmit(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <Form translate="yes" onSubmit={handleSubmit}>
                                <div>
                                    <Field
                                        name="email"
                                        type="text"
                                        placeholder='Your Email'
                                        className="login-custom-input"
                                    />
                                    {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                    {touched.email && errors.email ? (
                                        <div className="error">{errors.email}</div>
                                    ) : null}
                                </div>
                                <div>
                                    <div className="input-group">
                                        <Field
                                            name="password"
                                            type={this.state.showPass ? 'text' : 'password'}
                                            id="password"
                                            placeholder='Password'
                                            className="login-custom-input"
                                        />
                                        {/* <button type="button" id="btnToggle" className="toggle">
                                            <i id="eyeIcon" className="fa fa-eye"></i>
                                        </button> */}
                                        <span
                                            className={this.state.showPass ? 'icon hide' : 'icon show'}
                                            onClick={() => this.togglePassword()}
                                        />
                                    </div>
                                    {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                    {touched.password && errors.password ? (
                                        <div className="error">{errors.password}</div>
                                    ) : null}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '2% 8%' }}>
                                    <div>
                                        <Field
                                            type="checkbox"
                                            name="rememberMeFlag"
                                            checked={values.rememberMeFlag}
                                            className="custom-checkbox"
                                        />
                                        <label htmlFor="acceptTerms" className="form-check-label">Remember Me</label>
                                    </div>
                                    <Link to="/forgot-password" className="forgot-password">Forgot Password</Link>
                                </div>
                                <button type="submit" className="login-form-button">Login</button>
                                <p className="dont-text-first">
                                    Don&apos;t have an account?{'  '}
                                    <Link to="/email-account-registration" className="dont-text-second">Sign Up</Link>
                                </p>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        )
    }
}

// Customizable Area Start
const styles = StyleSheet.create({
});
// Customizable Area End