import React from "react";

// Customizable Area Start

import {
    StyleSheet
} from 'react-native';
// Customizable Area End

import DashboardController, {
    Props
} from './DashboardController.web';

import './Dashboard.web.css';

// import ProfileCompleteness from "../../../components/src/dashboard/ProfileCompleteness.web";
// import Overview from '../../../components/src/dashboard/Overview.web';
// import UserTraffic from "../../../components/src/dashboard/UserTraffic.web";
// import SalesLocation from "../../../components/src/dashboard/SalesLocation.web";

export default class Dashboard extends DashboardController {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <div className="dashboard-container">
                {/* <div className="dashboard-card top">
                    <span className="dashboard-card-header">Profile Completeness</span>
                    <ProfileCompleteness />
                </div>
                <div className="dashboard-card top">
                    <span className="dashboard-card-header">Overview</span>
                    <Overview />
                </div>
                <div className="dashboard-card bottom">
                    <span className="dashboard-card-header">User Traffic</span>
                    <UserTraffic />
                </div>
                <div className="dashboard-card bottom">
                    <span className="dashboard-card-header">Sales Location</span>
                    <SalesLocation />
                </div> */}
            </div>
        )
    }
}

// Customizable Area Start
const styles = StyleSheet.create({
});
// Customizable Area End
