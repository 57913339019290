// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import StoreLocator from "../../blocks/StoreLocator/src/StoreLocator";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import PushNotifications from "../../blocks/PushNotifications/src/PushNotifications";
import Sms from "../../blocks/Sms/src/Sms";
import Notifications from "../../blocks/Notifications/src/Notifications";
import Location from "../../blocks/Location/src/Location";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Customisableusersubscriptions from "../../blocks/CustomisableUserSubscriptions/src/Customisableusersubscriptions";
import ErpIntegration from "../../blocks/ErpIntegration/src/ErpIntegration";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import InventoryManagement from "../../blocks/InventoryManagement/src/InventoryManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import UploadMedia from "../../blocks/UploadMedia/src/UploadMedia";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import SenseTimeComputerVisionApiIntegration from "../../blocks/SenseTimeComputerVisionApiIntegration/src/SenseTimeComputerVisionApiIntegration";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm";
import CameraAccess from "../../blocks/CameraAccess/src/CameraAccess";
import WarehouseIntegration from "../../blocks/WarehouseIntegration/src/WarehouseIntegration";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ShoppingCart from "../../blocks/ShoppingCart/src/ShoppingCart";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import QrMapping from "../../blocks/QrMapping/src/QrMapping";
import ApiIntegration from "../../blocks/ApiIntegration/src/ApiIntegration";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import QrCodes from "../../blocks/QrCodes/src/QrCodes";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Payments from "../../blocks/Payments/src/Payments";
import DiscountsOffers from "../../blocks/DiscountsOffers/src/DiscountsOffers";
import CustomForm from "../../blocks/CustomForm/src/CustomForm";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import PhoneVerification from "../../blocks/PhoneVerification/src/PhoneVerification";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import ExitGateApiIntegration from "../../blocks/ExitGateApiIntegration/src/ExitGateApiIntegration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Analytics from "../../blocks/Analytics/src/Analytics";
import WarehouseIntegration5 from "../../blocks/WarehouseIntegration5/src/WarehouseIntegration5";
import Download from "../../blocks/Download/src/Download";
import DataImportexportcsv2 from "../../blocks/DataImportexportcsv2/src/DataImportexportcsv2";
import QrMapping2 from "../../blocks/QrMapping2/src/QrMapping2";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import PhoneVerification2 from "../../blocks/PhoneVerification2/src/PhoneVerification2";
import ProjectTemplates from "../../blocks/ProjectTemplates/src/ProjectTemplates";
import CfNventoryManagement from "../../blocks/CfNventoryManagement/src/CfNventoryManagement";
import ExitGateApiIntegration2 from "../../blocks/ExitGateApiIntegration2/src/ExitGateApiIntegration2";
import ErpIntegration2 from "../../blocks/ErpIntegration2/src/ErpIntegration2";
import Search from "../../blocks/Search/src/Search";
import PushNotifications2 from "../../blocks/PushNotifications2/src/PushNotifications2";
import SenseTimeComputerVisionApiIntegration3 from "../../blocks/SenseTimeComputerVisionApiIntegration3/src/SenseTimeComputerVisionApiIntegration3";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Sms2 from "../../blocks/Sms2/src/Sms2";
import StatisticsReports from "../../blocks/StatisticsReports/src/StatisticsReports";
import CustomForm2 from "../../blocks/CustomForm2/src/CustomForm2";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import Analytics3 from "../../blocks/Analytics3/src/Analytics3";
import CfInventoryManagement7 from "../../blocks/CfInventoryManagement7/src/CfInventoryManagement7";
import Location4 from "../../blocks/Location4/src/Location4";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import ApiIntegration8 from "../../blocks/ApiIntegration8/src/ApiIntegration8";


import WebRoutes from './WebRoutes';
import Loader from '../../components/src/Loader.web';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const routeMap = {
CustomForm2:{
 component:CustomForm2,
path:"/CustomForm2"},
DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
Analytics3:{
 component:Analytics3,
path:"/Analytics3"},
CfInventoryManagement7:{
 component:CfInventoryManagement7,
path:"/CfInventoryManagement7"},
Location4:{
 component:Location4,
path:"/Location4"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
ApiIntegration8:{
 component:ApiIntegration8,
path:"/ApiIntegration8"},

  StoreLocator: {
    component: StoreLocator,
    path: "/StoreLocator"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  PushNotifications: {
    component: PushNotifications,
    path: "/PushNotifications"
  },
  Sms: {
    component: Sms,
    path: "/Sms"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  Location: {
    component: Location,
    path: "/Location"
  },
  OrderSummary: {
    component: OrderSummary,
    path: "/OrderSummary"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  CustomisableUserSubscriptions: {
    component: Customisableusersubscriptions,
    path: "/CustomisableUserSubscriptions"
  },
  ErpIntegration: {
    component: ErpIntegration,
    path: "/ErpIntegration"
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: "/Onboardingguide"
  },
  InventoryManagement: {
    component: InventoryManagement,
    path: "/InventoryManagement"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  UploadMedia: {
    component: UploadMedia,
    path: "/UploadMedia"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  SenseTimeComputerVisionApiIntegration: {
    component: SenseTimeComputerVisionApiIntegration,
    path: "/SenseTimeComputerVisionApiIntegration"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  CameraAccess: {
    component: CameraAccess,
    path: "/CameraAccess"
  },
  WarehouseIntegration: {
    component: WarehouseIntegration,
    path: "/WarehouseIntegration"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  ShoppingCart: {
    component: ShoppingCart,
    path: "/ShoppingCart"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  QrMapping: {
    component: QrMapping,
    path: "/QrMapping"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  AdminConsole: {
    component: AdminConsole,
    path: "/AdminConsole"
  },
  QrCodes: {
    component: QrCodes,
    path: "/QrCodes"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: "/Ordermanagement"
  },
  OrderDetails: {
    component: OrderDetails,
    path: "/OrderDetails"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Splashscreen: {
    component: Splashscreen,
    path: "/Splashscreen"
  },
  Payments: {
    component: Payments,
    path: "/Payments"
  },
  DiscountsOffers: {
    component: DiscountsOffers,
    path: "/DiscountsOffers"
  },
  CustomForm: {
    component: CustomForm,
    path: "/CustomForm"
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/InvoiceBilling"
  },
  PhoneVerification: {
    component: PhoneVerification,
    path: "/PhoneVerification"
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: "/RolesPermissions"
  },
  ExitGateApiIntegration: {
    component: ExitGateApiIntegration,
    path: "/ExitGateApiIntegration"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },

Analytics:{
 component:Analytics,
path:"/Analytics"},
WarehouseIntegration5:{
 component:WarehouseIntegration5,
path:"/WarehouseIntegration5"},
Download:{
 component:Download,
path:"/Download"},
DataImportexportcsv2:{
 component:DataImportexportcsv2,
path:"/DataImportexportcsv2"},
QrMapping2:{
 component:QrMapping2,
path:"/QrMapping2"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
PhoneVerification2:{
 component:PhoneVerification2,
path:"/PhoneVerification2"},
ProjectTemplates:{
 component:ProjectTemplates,
path:"/ProjectTemplates"},
CfNventoryManagement:{
 component:CfNventoryManagement,
path:"/CfNventoryManagement"},
ExitGateApiIntegration2:{
 component:ExitGateApiIntegration2,
path:"/ExitGateApiIntegration2"},
ErpIntegration2:{
 component:ErpIntegration2,
path:"/ErpIntegration2"},
Search:{
 component:Search,
path:"/Search"},
PushNotifications2:{
 component:PushNotifications2,
path:"/PushNotifications2"},
SenseTimeComputerVisionApiIntegration3:{
 component:SenseTimeComputerVisionApiIntegration3,
path:"/SenseTimeComputerVisionApiIntegration3"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Sms2:{
 component:Sms2,
path:"/Sms2"},
StatisticsReports:{
 component:StatisticsReports,
path:"/StatisticsReports"},

StoreLocator:{
 component:StoreLocator,
path:"/StoreLocator"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
PushNotifications:{
 component:PushNotifications,
path:"/PushNotifications"},
Sms:{
 component:Sms,
path:"/Sms"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Location:{
 component:Location,
path:"/Location"},
OrderSummary:{
 component:OrderSummary,
path:"/OrderSummary"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
CustomisableUserSubscriptions:{
 component:Customisableusersubscriptions,
path:"/CustomisableUserSubscriptions"},
ErpIntegration:{
 component:ErpIntegration,
path:"/ErpIntegration"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
UploadMedia:{
 component:UploadMedia,
path:"/UploadMedia"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
SenseTimeComputerVisionApiIntegration:{
 component:SenseTimeComputerVisionApiIntegration,
path:"/SenseTimeComputerVisionApiIntegration"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
WarehouseIntegration:{
 component:WarehouseIntegration,
path:"/WarehouseIntegration"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
ShoppingCart:{
 component:ShoppingCart,
path:"/ShoppingCart"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
QrMapping:{
 component:QrMapping,
path:"/QrMapping"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
QrCodes:{
 component:QrCodes,
path:"/QrCodes"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Payments:{
 component:Payments,
path:"/Payments"},
DiscountsOffers:{
 component:DiscountsOffers,
path:"/DiscountsOffers"},
CustomForm:{
 component:CustomForm,
path:"/CustomForm"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
PhoneVerification:{
 component:PhoneVerification,
path:"/PhoneVerification"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
ExitGateApiIntegration:{
 component:ExitGateApiIntegration,
path:"/ExitGateApiIntegration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      // <View style={{ height: '100vh', width: '100vw' }}>
      //   <TopNav />
      //   {WebRoutesGenerator({ routeMap })}
      //   <ModalContainer />
      // </View>
      <div style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
        <ToastContainer />
        <WebRoutes />
      </div>

    );
  }
}

export default App;