import React from "react";

// Customizable Area Start

import {
    StyleSheet
} from 'react-native';

import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";

import { imgVeroShopLogo } from "./assets";

// Customizable Area End

import ForgotPasswordController, {
    Props
} from './ForgotPasswordController.web';

import './ForgotPassword.web.css';

import Loader from "../../../components/src/Loader.web";

const forgotPwdSchema = Yup.object().shape({
    email: Yup.string()
        .email('Please enter a valid email address.')
        .required('Email is required.')
});

export default class ForgotPassword extends ForgotPasswordController {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <div className="forgot-pwd-container">
                {this.state.loading && <Loader loading={this.state.loading} />}
                <div className="forgot-pwd-header">
                    <img src={imgVeroShopLogo} alt="Logo" className="Sa-logo" onClick={() => this.routeToAll('/home')} />
                </div>
                <div className="forgot-pwd-form-container">
                    <div className="forgot-pwd-form-header-text-container">
                        <h1 className="main-header">Reset Password</h1>
                        <h1 className="sub-header">We will send reset password link to<br /> your email ID</h1>
                    </div>
                    <Formik
                        initialValues={{ email: '' }}
                        validationSchema={forgotPwdSchema}
                        onSubmit={(values) => {
                            this.onSubmit(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <Form translate="yes" onSubmit={handleSubmit}>
                                <div>
                                    <Field
                                        name="email"
                                        type="text"
                                        placeholder='Enter Email'
                                        className="forgot-pwd-custom-input"
                                    />
                                    {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                    {touched.email && errors.email ? (
                                        <div className="error">{errors.email}</div>
                                    ) : null}
                                </div>
                                <button type="submit" className="forgot-pwd-form-button">Send</button>
                                <p className="forgot-pwd-text-first">
                                    Already have an account?{'  '}
                                    <Link to="/login" className="forgot-pwd-text-second">Sign In</Link>
                                </p>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        )
    }
}

// Customizable Area Start
const styles = StyleSheet.create({
});
// Customizable Area End