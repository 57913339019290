import React from "react";
// Customizable Area End
import "./ProductDetails.web.css";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  productImg1,
  productImg2
} from "./assets";

import ProductDetailsController, {
  Props
} from './ProductDetailsController.web';

import Loader from "../../../components/src/Loader.web";

export default class ProductDetails extends ProductDetailsController {

  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  async componentDidMount() {
    const productId = this.props.location.state.productId;
    await this.getProductDetailsApiCall(productId);
  }

  render() {
    const productDetails = this.state.productDetailData;
    const images = productDetails?.attributes?.images.map((images: any) => {
      return <img src={images.image} className="MuiGridListTile-imgFullWidth" />
    });
    return (
      <>
        <div className="product-detail-main-header">
          Product management / Manage Product / Product Details
        </div>
        <div className="product-detail-container">
          {this.state.loading && <Loader loading={this.state.loading} />}
          <div className="product-detail-panel-header">
            Product Details
          </div>
          <Grid container>
            <Grid item xs={12} className="product-details-view">
              <Grid container>
                <Grid item xs={6}>
                  <div className="product-detail-left-side-container">
                    {images}
                  </div>
                </Grid>
                <Grid item xs={6} style={{paddingLeft: '30px', paddingRight: '35px'}}>
                  <div className="product-detail-right-side-container">
                    <Grid item xs={6}>Exprience </Grid>
                    <Grid item xs={6}>{productDetails?.attributes?.experience}</Grid>
                    <Grid item xs={6}>Brand </Grid>
                    <Grid item xs={6}>{productDetails?.attributes?.brand}</Grid>
                    <Grid item xs={6}>Manufacturer</Grid>
                    <Grid item xs={6}>{productDetails?.attributes?.manufacturer}</Grid>
                    <Grid item xs={6}>Country of orgin</Grid>
                    <Grid item xs={6}>{productDetails?.attributes?.country_of_orgin}</Grid>
                    <Grid item xs={6}>Package content</Grid>
                    <Grid item xs={6}>{productDetails?.attributes?.package_content}</Grid>
                    <Grid item xs={6}>Package Description</Grid>
                    <Grid item xs={6}>{productDetails?.attributes?.package_description}</Grid>
                    <Grid item xs={6}>Category</Grid>
                    <Grid item xs={6}>{productDetails?.attributes?.category_id}</Grid>
                    <Grid item xs={6}>UPC</Grid>
                    <Grid item xs={6}>{productDetails?.attributes?.upc}</Grid>
                    <Grid item xs={6}>Unit Price</Grid>
                    <Grid item xs={6}>{productDetails?.attributes?.unit_price}</Grid>
                    <Grid item xs={6}>Promotional price</Grid>
                    <Grid item xs={6}>{productDetails?.attributes?.promotional_price}</Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }
}
