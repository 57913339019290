import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from 'react-router-dom';

import Login from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import ResetPasswordConfirmation from '../../blocks/forgot-password/src/ResetPasswordConfirmation.web'
import ResetPassword from '../../blocks/forgot-password/src/ResetPassword.web';
import PasswordChangeNotification from '../../blocks/forgot-password/src/PasswordChangeNotification.web';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import ProductManagement from '../../blocks/WarehouseIntegration/src/ProductManagement.web';
import ProductDetails from "../../blocks/WarehouseIntegration/src/ProductDetails.web";
import PrivateRoute from './privateRoute.web';

import './App.css';

class WebRoutes extends Component {
    render() {
        const { history } = this.props;
        return (
            <Router history={history}>
                <Switch>
                    <PrivateRoute path="/" exact component={Login} />
                    <Route path="/login" exact component={Login} />
                    <Route path="/email-account-registration" exact component={EmailAccountRegistration} />
                    <Route path="/forgot-password" exact component={ForgotPassword} />
                    <Route path="/reset-password-confirm" exact component={ResetPasswordConfirmation} />
                    <Route path="/reset-password" exact component={ResetPassword} />
                    <Route path="/reset-password-notification" exact component={PasswordChangeNotification} />
                    <PrivateRoute path="/product-details" exact component={ProductDetails} />
                    <PrivateRoute path="/home" exact component={Dashboard} />
                    <PrivateRoute path="/productManagement" exact component={ProductManagement} />
                </Switch>
            </Router>
        );
    }
}

export default withRouter(WebRoutes);